import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import SectionHeader from '../../components/SectionHeader'
import SectionTextTwoColumns from '../../components/SectionTextTwoColumns'
import Layout from '../../layout/Layout'

export default function Home() {
  const { headerBg } = useStaticQuery(
    graphql`
      query {
        headerBg: file(
          relativePath: { eq: "nasze-uslugi/transport-ponadgabarytowy.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  return (
    <Layout
      metaTitle="Transport ponadgabarytowy"
      metaDescription="Jeśli chcesz zlecić transport ponadgabarytowy, mamy ofertę spełniającą Twoje wymagania. Tworzymy doświadczony zespół spedytorów, który wielokrotnie realizował tego rodzaju zlecenia, dzięki czemu doskonale wiemy, jak je organizować. "
      slug="/uslugi/transport-ponadgabarytowy"
    >
      <SectionHeader
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            ponadgabarytowy
          </>
        }
        titleBorder="white"
        lead="Jeśli chcesz zlecić transport ponadgabarytowy, mamy ofertę spełniającą Twoje wymagania. Tworzymy doświadczony zespół spedytorów, który wielokrotnie realizował tego rodzaju zlecenia, dzięki czemu doskonale wiemy, jak je organizować."
        content={
          <>
            Możesz na nas liczyć w&nbsp;przypadku skomplikowanego transportu
            ponadnormatywnego. Pozostajemy do Twojej dyspozycji nawet wówczas,
            gdy pojawia się konieczność zastosowania najbardziej zaawansowanych
            pojazdów, technologii i&nbsp;metod logistycznych. Wyróżnia nas dobór
            rozwiązań ograniczających koszty do minimum, co pozwala na
            połączenie wysokiej jakości i&nbsp;zarazem korzystnej ceny
            świadczonych usług.
          </>
        }
        buttonType="bordered"
        scroll="#czytaj-wiecej"
        halfBg
        bgImage={getImage(headerBg)}
      />
      <SectionTextTwoColumns
        id="czytaj-wiecej"
        smallTitle="Transport ponadgabarytowy"
        title={
          <>
            Przewozy <br />
            ponadgabarytowe <br />
            elastyczność i&nbsp;profesjonalizm
          </>
        }
        lead={
          <>
            Kluczowym aspektem, który należy wziąć pod uwagę, organizując
            przewozy ponadgabarytowe, jest dostęp do odpowiednich pojazdów. Jako
            że zapewniamy dobór sprawdzonych oraz rzetelnych Podwykonawców,
            możemy wykonać transport ściśle dostosowany do Twoich ładunków.
          </>
        }
      >
        <p>
          Bezpieczeństwo to podstawowy czynnik, na którym się skupiamy. Obejmuje
          on zarówno właściwy dobór metod i&nbsp;środków przewozu, jak
          i&nbsp;formalności związanych z&nbsp;jego realizacją.
        </p>
        <p>
          Jednym z elementów naszej oferty obejmującej transport gabarytów jest
          wsparcie Klientów w&nbsp;czynnościach celnych. Wiemy jak szybko
          i&nbsp;sprawnie załatwić wszelkie niezbędne dokumenty, wnioski
          i&nbsp;procedury, dzięki czemu oszczędzasz czas na organizacji kwestii
          administracyjnych. Proponujemy też profesjonalne wsparcie przy zakupie
          ubezpieczeń CARGO – doradzamy w&nbsp;wyborze najlepszej polisy,
          a&nbsp;na życzenie Klienta dopilnowujemy również prawidłowej
          realizacji transakcji. Ubezpieczenie daje pewność, że nawet
          w&nbsp;razie niespodziewanych wydarzeń przewóz ponadgabarytowy nie
          przyniesie strat, ponieważ ładunki będą chronione do pełnej wartości
          sumy ubezpieczenia.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport ponadgabarytowy"
        title={<>Transport ponadnormatywny w&nbsp;dowolnym kierunku</>}
        lead={
          <>
            Rozbudowana sieć partnerów w&nbsp;całej Europie pozwala wykonać
            transport ponadnormatywny w&nbsp;dowolnym kierunku. Docieramy
            wszędzie tam, dokąd mają zostać dostarczone Twoje ładunki. Możesz
            zlecić nam przewozy ciężkie do dowolnego miejsca, w&nbsp;którym
            dostępna jest infrastruktura drogowa.
          </>
        }
      >
        <p>
          Kompleksowa obsługa i&nbsp;kontrola procesów transportowych to
          zasługa, zarówno wysoko wykwalifikowanej i&nbsp;doświadczonej kadry,
          jak i&nbsp;umiejętności sprawnego wykorzystania wysoko wydajnych,
          nowoczesnych technologii, które optymalizują i&nbsp;usprawniają
          transport ponadgabarytowy.
        </p>
        <p>
          W ramach przewozu ciężkiego w&nbsp;razie potrzeby organizujemy całą
          kolumnę pojazdów, która zapewnia efektywne i&nbsp;zgodne
          z&nbsp;obowiązującymi przepisami wykonanie zlecenia. Jesteśmy
          w&nbsp;stanie odebrać oraz dostarczyć przedmioty i&nbsp;obiekty nawet
          o&nbsp;bardzo dużych wymiarach, nietypowych kształtach czy
          ponadnormatywnej masie. Realizujemy transport w&nbsp;taki sposób, żeby
          cało dotarł na miejsce, a&nbsp;jednocześnie był w&nbsp;pełni
          bezpieczny, zarówno dla infrastruktury drogowej oraz ruchu, jak
          i&nbsp;środowiska. To zadanie, które wymaga wiedzy, doświadczenia
          i&nbsp;kwalifikacji, dlatego warto powierzyć je specjalistom
          z&nbsp;naszego zespołu.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport ponadgabarytowy"
        title={<>Przejrzyste warunki, uczciwa współpraca</>}
        lead={
          <>
            Każdy pojazd, którym odbywa się przewóz towarów, podlega precyzyjnej
            i&nbsp;całodobowej kontroli. Dzięki temu w&nbsp;dowolnej chwili
            możesz dowiedzieć się, gdzie znajdują się Twoje ładunki i&nbsp;
            kiedy mniej więcej dotrą do celu. Proponujemy jasne warunki, minimum
            formalności i&nbsp;uczciwą współpracę.
          </>
        }
      >
        <p className="lead">
          Do naszych obowiązków należy zapewnienie wymaganych zezwoleń
          odpowiedniej kategorii, która jest dopasowana do specyfiki przewozów
          ponadgabarytowych. Dzięki temu możemy zagwarantować sprawny transport
          ciężki przedmiotów i obiektów wszelkiego rodzaju.
        </p>
        <p>
          <b>Z powodzeniem dostarczamy do celu m.in.:</b>
          <ul>
            <li>
              rozbudowane maszyny i sprzęty przemysłowe, budowlane
              i&nbsp;transportowe
            </li>
            <li>podzespoły konstrukcyjne</li>
            <li>elementy budowlane</li>
            <li>pojazdy ponadgabarytowe</li>
            <li>zbiorniki różnego typu</li>
            <li>sprzęt rolniczy</li>
            <li>gotowe konstrukcje, np. stalowe, betonowe, drewniane</li>
            <li>elementy infrastruktury drogowej.</li>
          </ul>
        </p>
        <p>
          Wiemy, że interesuje Cię terminowość i&nbsp;bezpieczeństwo dostaw,
          dlatego tym dwóm aspektom podporządkowujemy realizację zleceń.
          Dobieramy wyłącznie sprawdzonych i&nbsp;pewnych partnerów, którzy dają
          gwarancję sprawnego i&nbsp;zgodnego z&nbsp;wymaganiami oraz przepisami
          transportu ponadnormatywnego. Zapewniając precyzyjny przewóz
          ponadgabarytowy, oferujemy – na dodatkowe życzenie Klienta –
          odpowiedni załadunek i&nbsp;równie efektywny rozładunek. Przed
          wykonaniem zlecenia przeprowadzamy szczegółowe analizy, które mają na
          celu dobranie jak najlepszych środków i&nbsp;metod transportu
          gabarytów.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport ponadgabarytowy"
        title={<>Sprawny transport gabarytowy dowolnych ładunków</>}
        lead={
          <>
            Zdajemy sobie sprawę z różnorodności potrzeb naszych Klientów,
            w&nbsp;związku z&nbsp;czym stawiamy na elastyczne
            i&nbsp;indywidualne podejście do każdego zlecenia. Zawsze bierzemy
            pod uwagę wszelkie utrudnienia, z&nbsp;którymi radzimy sobie poprzez
            profesjonalne planowanie.
          </>
        }
      >
        <p>
          Możesz liczyć na nasze wsparcie niezależnie od tego, czy chodzi
          o&nbsp;jednorazowy, czy regularny transport ciężki lub
          ponadnormatywny. Polecamy przewozy ponadgabarytowe zarówno małym
          firmom, jak i&nbsp;największym przedsiębiorstwom z&nbsp;dowolnych
          branż.
        </p>
        <p>
          Obsługujemy transport ponadgabarytowy na terenie całej Europy,
          a&nbsp;także w&nbsp;Azji. Docieramy z&nbsp;Twoimi ładunkami wszędzie
          tam, gdzie dostępna jest infrastruktura drogowa. Dysponujemy
          doświadczeniem w&nbsp;realizacji zleceń, zarówno na Zachodzie, jak
          i&nbsp;Wschodzie – znamy procedury obowiązujące nawet w&nbsp;dalekich
          kulturowo krajach byłego ZSRR. Możesz liczyć na indywidualną obsługę
          i&nbsp;pełną dyspozycyjność – gwarancja szybkiego kontaktu
          z&nbsp;dedykowanym opiekunem to stały element naszej oferty.
          Zapraszamy do współpracy, jeśli poszukujesz firmy spedycyjnej, której
          możesz zlecić przewozy ponadnormatywne!
        </p>
      </SectionTextTwoColumns>
    </Layout>
  )
}
